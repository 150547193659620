var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "verification-info" },
    [
      _c("h2", { staticClass: "question-title with-subtext mt-2" }, [
        _vm._v(
          " Ok great! The Winona physician is ready to review your information. "
        ),
      ]),
      _c("p", { staticClass: "sub-text mb-3" }, [
        _vm._v(
          " You're almost done. In the next step, we'll verify your identity so your doctor can legally write you a prescription. "
        ),
      ]),
      _c(
        "p",
        { staticClass: "sub-text mb-2", staticStyle: { color: "#a783ff" } },
        [_vm._v("2 minute task")]
      ),
      _c("div", { staticClass: "mt-5" }, [
        _c("h2", [_vm._v("Here's what's needed:")]),
        _c(
          "h2",
          { staticClass: "med-font mb-4 row align-items-center" },
          [
            _c(
              "b-col",
              { attrs: { cols: "auto" } },
              [
                _c("b-avatar", {
                  attrs: { variant: "primary", text: "1", size: "1.5rem" },
                }),
              ],
              1
            ),
            _c("b-col", [
              _vm._v(
                " A photo of your ID (required by your state to verify identity) "
              ),
            ]),
          ],
          1
        ),
        _c(
          "h2",
          { staticClass: "med-font row align-items-center" },
          [
            _c(
              "b-col",
              { attrs: { cols: "auto" } },
              [
                _c("b-avatar", {
                  attrs: { variant: "primary", text: "2", size: "1.5rem" },
                }),
              ],
              1
            ),
            _c("b-col", [
              _vm._v(
                " A photo of your face (used to confirm the ID is yours) "
              ),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              staticClass: "mt-4",
              attrs: { variant: "info", pill: "", block: "" },
              on: { click: _vm.next },
            },
            [_c("b-icon-lock"), _vm._v("Continue")],
            1
          ),
          _c("p", { staticClass: "sub-text mt-5" }, [
            _vm._v(
              " A government-issued ID is required when purchasing any prescription medication, as the medical provider will need to verify your identity before they can determine if prescription medication is right for you. Your personal identifying information, medical history or ID will never be shared outside of our secure system. "
            ),
          ]),
        ],
        1
      ),
      _c("SideModal", {
        attrs: { show: _vm.showWhyDialog, "body-class": "why-body" },
        on: {
          exit: function ($event) {
            return _vm.hideWhyDialog()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h1", { staticClass: "h2 mt-6 mb-0 heading text-primary" }, [
                  _vm._v("Onboarding"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-row",
                          { staticClass: "mt-5" },
                          [
                            _c("b-col", [
                              _c("h3", [_vm._v("Identity Verification")]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass:
                                  "d-flex flex-column justify-content-between",
                                attrs: { cols: "9" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-small text-muted" },
                                  [_vm._v("Why we need this information?")]
                                ),
                                _c("p", [
                                  _vm._v(
                                    " Government issued ID is required when purchasing any prescription medication, as the medical provider will need to verify your identity before they can determine if prescription medication is right for you. Your personal identifying information, medical history or ID will never be shared outside of our secure system. "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "align-self-center text-right",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "min-width": "250px" },
                            attrs: { pill: "", variant: "info" },
                            on: {
                              click: function ($event) {
                                return _vm.hideWhyDialog()
                              },
                            },
                          },
                          [_vm._v("OK")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "bg-overlay" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }