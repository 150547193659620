var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "idPhoto" },
    [
      _c("IdModal", {
        attrs: {
          show: _vm.showModal,
          "header-class": "id-modal",
          "body-class": "id-body-modal",
        },
        on: {
          exit: function ($event) {
            return _vm.hideModal()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("img", {
                  attrs: { src: require("@/assets/images/id.png"), alt: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "h3",
                  {
                    staticClass: "sub-text text-uppercase text-primary",
                    staticStyle: { "letter-spacing": "0.16em" },
                  },
                  [_vm._v(" Identity Verification ")]
                ),
                _c(
                  "h2",
                  {
                    staticClass:
                      "heading h1 text-default mt-5 mb-2 initial-line-height",
                  },
                  [_vm._v(" Why do you need a photo of my ID? ")]
                ),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " We use your ID photo to verify your identity, for example your name and date of birth. Telemedicine laws require us to verify this information before prescribing medication. "
                  ),
                ]),
                _c(
                  "h2",
                  {
                    staticClass:
                      "heading h1 text-default mt-4 mb-2 initial-line-height",
                  },
                  [_vm._v(" Who sees this? ")]
                ),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " The photo of your ID will be stored securely and will only be shared with the patient support team and our identity verification platform. "
                  ),
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-5",
                    attrs: { pill: "", block: "", variant: "info" },
                    on: {
                      click: function ($event) {
                        return _vm.hideModal()
                      },
                    },
                  },
                  [_vm._v(" I understand ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", {
        staticClass: "bg-overlay",
        on: {
          click: function ($event) {
            return _vm.hideModal()
          },
        },
      }),
      _c("h2", { staticClass: "question-title with-subtext" }, [
        _vm._v(
          " " +
            _vm._s(_vm.isAndroid11Webview ? "Send us" : "Upload") +
            " a photo of your ID "
        ),
      ]),
      _c("p", { staticClass: "sub-text mb-4" }, [
        _vm._v(
          " In order to legally prescribe medications, we need a valid government-issued ID that matches your name and date of birth. "
        ),
        _c(
          "a",
          {
            staticClass: "text-underline",
            staticStyle: { color: "#828282" },
            on: {
              click: function ($event) {
                return _vm.openModal()
              },
            },
          },
          [_vm._v(" Why do you need this? ")]
        ),
      ]),
      _c("p", { staticClass: "sub-text mb-0" }, [
        _vm._v("Acceptable Forms of ID"),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { staticClass: "list-id", attrs: { cols: "12", md: "6" } },
            [
              _c("ul", [
                _c("li", [_vm._v("Driver License")]),
                _c("li", [_vm._v("Passport")]),
                _c("li", [_vm._v("Identification Card")]),
              ]),
            ]
          ),
          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
            _c("ul", [
              _c("li", [_vm._v("Permanent Resident Card")]),
              _c("li", [_vm._v("Consular Card")]),
            ]),
          ]),
        ],
        1
      ),
      !_vm.isMobileOrTablet
        ? _c(
            "div",
            { staticClass: "fs-exclude" },
            [
              _c("div", { staticClass: "photo-holder fs-mask" }, [
                (_vm.picture || _vm.idPreview) &&
                _vm.uploadFileType !== "application/pdf"
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-crop",
                        on: { click: _vm.openCropModal },
                      },
                      [_c("b-icon", { attrs: { icon: "crop" } })],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "photo",
                    style: {
                      backgroundImage:
                        _vm.isPhotoTaken || _vm.showId || _vm.identificationURL
                          ? ""
                          : "url(" + require("@/assets/images/id.png") + ")",
                    },
                  },
                  [
                    _vm.isPhotoTaken
                      ? _c("img", {
                          staticClass: "fs-exclude",
                          attrs: { src: _vm.picture, alt: "" },
                        })
                      : _vm.showId
                      ? _c("img", {
                          staticClass: "fs-exclude",
                          staticStyle: { "object-fit": "contain" },
                          attrs: { src: _vm.idPreview },
                        })
                      : _vm.identificationURL
                      ? _c("img", {
                          staticClass: "fs-exclude",
                          style: {
                            width:
                              _vm.identificationFileType === "application/pdf"
                                ? "auto"
                                : "100%",
                          },
                          attrs: { src: _vm.identificationURL },
                        })
                      : _vm._e(),
                  ]
                ),
              ]),
              !_vm.isPhotoTaken && !_vm.showId && !_vm.identificationURL
                ? _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "photo-btn",
                              attrs: {
                                block: "",
                                pill: "",
                                variant: "info",
                                disabled: _vm.isLoadingImage,
                              },
                            },
                            [
                              _c("input", {
                                ref: "identification",
                                staticClass: "hide-file-input",
                                attrs: {
                                  type: "file",
                                  id: "identification",
                                  accept: _vm.ACCEPTED_FILE_PATTERNS,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.idUpload()
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "mb-0",
                                  attrs: { for: "identification" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isLoadingImage
                                          ? "Loading..."
                                          : "SELECT PHOTO"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "mb-3 d-lg-none" }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "outline-info",
                                disabled: _vm.saving,
                                pill: "",
                                block: "",
                              },
                              on: { click: _vm.showCamera },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isPhotoTaken ? "RETAKE" : "TAKE PHOTO"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "photo-btn",
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-info",
                                disabled: _vm.saving || _vm.isLoadingImage,
                              },
                            },
                            [
                              _c("input", {
                                ref: "identification",
                                staticClass: "hide-file-input",
                                attrs: {
                                  type: "file",
                                  id: "identification",
                                  accept: _vm.ACCEPTED_FILE_PATTERNS,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.idUpload()
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "mb-0",
                                  attrs: { for: "identification" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isLoadingImage
                                          ? "Loading..."
                                          : "REUPLOAD"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "mb-3 d-md-none" }),
                        ],
                        1
                      ),
                      _c("b-col", {
                        staticClass: "d-block d-md-none py-2",
                        attrs: { cols: "12" },
                      }),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "outline-info",
                                disabled: _vm.saving || _vm.isLoadingImage,
                                pill: "",
                                block: "",
                              },
                              on: { click: _vm.showCamera },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isPhotoTaken ? "Retake" : "TAKE PHOTO"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "mb-3 d-md-none" }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm.isPhotoTaken || _vm.showId || _vm.identificationURL
                ? _c(
                    "b-button",
                    {
                      staticClass: "mt-3",
                      attrs: {
                        pill: "",
                        variant: "info",
                        disabled: _vm.saving,
                        block: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitAnswer({ isSkipped: false })
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.saving ? "Saving..." : "CONFIRM") + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "modal",
                {
                  attrs: {
                    id: "takePhoto",
                    size: "md",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                    "no-close-on-esc": "",
                    "no-close-on-backdrop": "",
                  },
                },
                [
                  _c("video", {
                    ref: "camera",
                    staticStyle: { "background-color": "#000" },
                    attrs: { width: "100%", height: "325", autoplay: "" },
                  }),
                  _c("canvas", {
                    ref: "canvas",
                    staticClass: "photo-taken",
                    attrs: { width: 325, height: 250 },
                  }),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { pill: "", block: "", variant: "info" },
                              on: { click: _vm.takePhoto },
                            },
                            [_vm._v(" Capture ")]
                          ),
                          _c("div", { staticClass: "mb-3 d-md-none" }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-info",
                              },
                              on: { click: _vm.hideCameraModal },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  staticClass: "fs-exclude",
                  attrs: {
                    id: "crop",
                    size: "md",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                    "no-close-on-esc": "",
                    "no-close-on-backdrop": "",
                  },
                },
                [
                  _c("vue-croppie", {
                    ref: "croppieRef",
                    attrs: {
                      enableOrientation: false,
                      enableResize: false,
                      boundary: { width: 300, height: 200 },
                      viewport: { width: 300, height: 200 },
                    },
                  }),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { pill: "", block: "", variant: "info" },
                              on: { click: _vm.cropImage },
                            },
                            [_vm._v(" Crop ")]
                          ),
                          _c("div", { staticClass: "mb-3 d-md-none" }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-info",
                              },
                              on: { click: _vm.closeCropModal },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("MobileUpload", {
                staticClass: "fs-exclude",
                attrs: {
                  "id-size": "",
                  isAndroid11Webview: _vm.isAndroid11Webview,
                  existingPhoto: _vm.identificationURL,
                  existingPhotoFileType: _vm.identificationFileType,
                },
                on: {
                  submitAnswer: _vm.setMobilePhoto,
                  showAlert: _vm.showAlert,
                  hideAlert: _vm.hideAlert,
                  "update:isAndroid11Webview": function ($event) {
                    _vm.isAndroid11Webview = $event
                  },
                  "update:is-android11-webview": function ($event) {
                    _vm.isAndroid11Webview = $event
                  },
                  skip: function ($event) {
                    return _vm.submitAnswer({ isSkipped: true })
                  },
                },
              }),
            ],
            1
          ),
      _c("div", { staticClass: "text-center" }, [
        _vm.identificationUploadStatus !== "UPLOADED" && !_vm.isAndroid11Webview
          ? _c(
              "a",
              {
                staticClass:
                  "text-default text-underline d-inline-block mt-4 py-3 mx-auto",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    _vm.showSkipDialog = true
                  },
                },
              },
              [_vm._v(" Having Trouble Uploading Photo? ")]
            )
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "trouble",
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "content-class": "dark-bg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-stacking": "",
          },
          model: {
            value: _vm.showSkipDialog,
            callback: function ($$v) {
              _vm.showSkipDialog = $$v
            },
            expression: "showSkipDialog",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo dark" }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c(
                "p",
                { staticClass: "sub-text", staticStyle: { color: "inherit" } },
                [
                  _vm._v(
                    " You can bypass uploading your ID now but you will need to upload it later in order for your medical provider to verify your identity. "
                  ),
                ]
              ),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { "min-width": "100px", width: "100%" },
                          attrs: { variant: "info", pill: "" },
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v(" Upload Now ")]
                      ),
                      _c("div", { staticClass: "separator" }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "modal-cancel-btn",
                          staticStyle: { "min-width": "100px", width: "100%" },
                          attrs: { variant: "outline-info", pill: "" },
                          on: {
                            click: function ($event) {
                              return _vm.submitAnswer({ isSkipped: true })
                            },
                          },
                        },
                        [_vm._v(" Skip Uploading ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }