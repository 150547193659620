var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patient-information" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("h2", { staticClass: "question-title with-subtext" }, [
              _vm._v(
                " Ok great! Winona Physician is ready to review your information, and provide you with a medical consult. "
              ),
            ]),
            _c("h3", { staticClass: "sub-text mb-0" }, [
              _vm._v("Please fill in the information below."),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-5" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "label",
                        { staticClass: "input-label", attrs: { for: "fname" } },
                        [_vm._v("First Name*")]
                      ),
                      _c("b-form-input", {
                        staticClass: "fs-mask onboarding-input",
                        attrs: {
                          type: "text",
                          name: "fname",
                          required: "",
                          readonly: _vm.nonrx,
                        },
                        model: {
                          value: _vm.patientData.firstname,
                          callback: function ($$v) {
                            _vm.$set(_vm.patientData, "firstname", $$v)
                          },
                          expression: "patientData.firstname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "label",
                        { staticClass: "input-label", attrs: { for: "lname" } },
                        [_vm._v("Last Name*")]
                      ),
                      _c("b-form-input", {
                        staticClass: "fs-mask onboarding-input",
                        attrs: {
                          type: "text",
                          name: "lname",
                          required: "",
                          readonly: _vm.nonrx,
                        },
                        model: {
                          value: _vm.patientData.lastname,
                          callback: function ($$v) {
                            _vm.$set(_vm.patientData, "lastname", $$v)
                          },
                          expression: "patientData.lastname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.nonrx
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "dob" },
                            },
                            [_vm._v("Date of Birth*")]
                          ),
                          _c("b-form-input", {
                            staticClass: "fs-mask onboarding-input birthdate",
                            attrs: { type: "text", name: "dob", readonly: "" },
                            model: {
                              value: _vm.birthday,
                              callback: function ($$v) {
                                _vm.birthday = $$v
                              },
                              expression: "birthday",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "state" },
                            },
                            [_vm._v("State*")]
                          ),
                          _c("b-form-input", {
                            staticClass: "onboarding-input fs-mask",
                            attrs: {
                              type: "text",
                              name: "state",
                              readonly: "",
                            },
                            model: {
                              value: _vm.patientData.statename,
                              callback: function ($$v) {
                                _vm.$set(_vm.patientData, "statename", $$v)
                              },
                              expression: "patientData.statename",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "label",
                        { staticClass: "input-label", attrs: { for: "email" } },
                        [_vm._v("Email Address*")]
                      ),
                      _c("b-form-input", {
                        staticClass: "fs-mask onboarding-input",
                        attrs: { type: "text", name: "email", readonly: "" },
                        model: {
                          value: _vm.patientData.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.patientData, "email", $$v)
                          },
                          expression: "patientData.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "label",
                        { staticClass: "input-label", attrs: { for: "phone" } },
                        [_vm._v("Phone Number*")]
                      ),
                      _c("b-form-input", {
                        staticClass: "fs-mask onboarding-input",
                        attrs: {
                          type: "text",
                          name: "phone",
                          formatter: _vm.formatPhoneNumber,
                          required: "",
                        },
                        model: {
                          value: _vm.patientData.phoneno,
                          callback: function ($$v) {
                            _vm.$set(_vm.patientData, "phoneno", $$v)
                          },
                          expression: "patientData.phoneno",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "info",
                    disabled: _vm.disabled,
                  },
                  on: { click: _vm.submitAnswer },
                },
                [_vm._v("Continue")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }