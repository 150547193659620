<template>
  <div class="patient-information">
    <b-row>
      <b-col>
        <h2 class="question-title with-subtext">
          Ok great! Winona Physician is ready to review your information, and
          provide you with a medical consult.
        </h2>
        <h3 class="sub-text mb-0">Please fill in the information below.</h3>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-row class="mt-4">
          <b-col cols="12" md="6" class="mb-3">
            <label for="fname" class="input-label">First Name*</label>
            <b-form-input
              class="fs-mask onboarding-input"
              type="text"
              name="fname"
              v-model="patientData.firstname"
              required
              :readonly="nonrx"
            />
          </b-col>
          <b-col cols="12" md="6" class="mb-3">
            <label for="lname" class="input-label">Last Name*</label>
            <b-form-input
              class="fs-mask onboarding-input"
              type="text"
              name="lname"
              v-model="patientData.lastname"
              required
              :readonly="nonrx"
            />
          </b-col>
        </b-row>
        <b-row v-if="!nonrx">
          <b-col cols="12" md="6" class="mb-3">
            <label for="dob" class="input-label">Date of Birth*</label>
            <b-form-input
              class="fs-mask onboarding-input birthdate"
              type="text"
              name="dob"
              v-model="birthday"
              readonly
            />
          </b-col>
          <b-col cols="12" md="6" class="mb-3">
            <label for="state" class="input-label">State*</label>
            <b-form-input
              type="text"
              name="state"
              class="onboarding-input fs-mask"
              v-model="patientData.statename"
              readonly
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="mb-3">
            <label for="email" class="input-label">Email Address*</label>
            <b-form-input
              class="fs-mask onboarding-input"
              type="text"
              name="email"
              v-model="patientData.email"
              readonly
            />
          </b-col>
          <b-col cols="12" md="6" class="mb-3">
            <label for="phone" class="input-label">Phone Number*</label>
            <b-form-input
              class="fs-mask onboarding-input"
              type="text"
              name="phone"
              v-model="patientData.phoneno"
              :formatter="formatPhoneNumber"
              required
            />
          </b-col>
        </b-row>
        <b-button
          pill
          block
          variant="info"
          class="mt-4"
          @click="submitAnswer"
          :disabled="disabled"
          >Continue</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Services
import { OnboardingService } from "@/services/onboarding.service";
import { generateEventId } from "@/services/util";
// Mixins
import mixins from "@/components/form/mixins";

export default {
  props: {
    nonrx: {
      type: Boolean,
      default: false,
    },
    onboardingData: {
      type: Object,
    },
    onboardingflow: {
      type: String,
      default: "flowA",
    },
  },
  mixins: [mixins],
  data() {
    return {
      patientData: {
        id: null,
        firstname: "",
        lastname: "",
        birthday: "",
        statename: "",
        email: "",
        phoneno: "",
      },
      disabled: false,
    };
  },
  computed: {
    birthday() {
      if (this.patientData.birthday) {
        let dateArray = this.patientData.birthday.split("-");
        return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    async submitAnswer() {
      try {
        this.disabled = true;
        let answer = {
          firstname: this.patientData.firstname,
          lastname: this.patientData.lastname,
          phoneno: this.patientData.phoneno,
        };
        const { data } = await OnboardingService.updatePatientOnboarding(
          5.1,
          answer
        );
        if (data) {
          // SEGMENT
          this.patientData.firstname = data.firstname;
          this.patientData.lastname = data.lastname;
          this.patientData.phoneno = data.phoneno;

          this.$emit("updateUserInfo", this.patientData);

          // window.analytics.identify(this.onboardingData.id, {
          //   customerId: this.onboardingData.id,
          //   email: this.onboardingData.email,
          //   firstname: this.patientData.firstname,
          //   lastname: this.patientData.lastname,
          //   phone: this.patientData.phoneno
          // });

          if (
            this.onboardingData.onboardingstep === 5.1 &&
            this.onboardingData.firstname === undefined
          ) {
            // TRANSFERRED to verification.vue
            // window.analytics.track("Identity Verification Started", {
            //   customerId: this.onboardingData.id,
            // },
            // {
            //     context: await this.$store.dispatch('geDeviceDetails')
            // });
            // // GTM: Mariwin
            // window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({
            //   event: "Identity Verification Started",
            //   customerID: this.onboardingData.id,
            //   firstName: this.patientData.firstname,
            //   lastName: this.patientData.lastname,
            //   phone: this.patientData.phoneno
            // });
            // // FB Pixel Tracking
            // console.log('FB TRACKING VerificationStarted');
            // fbq('track', 'PageView');
            // fbq('trackCustom', 'VerificationStarted', {
            //   external_id: this.onboardingData.id,
            //   phone: this.patientData.phoneno
            // });
          }

          this.$emit("setStep", "idphoto");
          this.disabled = false;
        }
      } catch (err) {
        if (err.status == 401) {
          this.$router.push("/login");
        } else {
          this.disabled = false;
          this.$emit("showAlert", err.message);
        }
      }
    },
  },
  async mounted() {
    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        customerId: this.analyticsId,
        screen: "4.1-A",
        onboardingflow: this.onboardingflow,
        firstname: this.onboardingData.firstname,
        lastname: this.onboardingData.lastname,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
        ...(await this.$store.dispatch("getVoluumDetails")),
      },
      {
        referrer: await this.$store.dispatch("getEverflowDetails"),
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );

    for (let item of Object.keys(this.patientData)) {
      if (this.onboardingData.hasOwnProperty(item)) {
        this.$set(this.patientData, item, this.onboardingData[item]);
      }
    }
    if (this.patientData.phoneno != "") {
      this.patientData.phoneno = this.formatPhoneNumber(
        this.patientData.phoneno
      );
    }
  },
};
</script>
