var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("OnboardingProgress", {
        attrs: { hide: _vm.hideBackButton, progress: _vm.progress },
        on: { back: _vm.prev },
      }),
      _c(
        "b-row",
        { staticClass: "onboarding-wrapper" },
        [
          _c("OnboardingHeader", {
            attrs: { step: "4", hide: _vm.hideBackButton, title: _vm.title },
            on: { back: _vm.prev },
          }),
          _c(
            "b-col",
            {
              staticClass: "mt-6 pt-3 onboarding-content",
              attrs: { cols: "12", lg: "5", xl: "4", xxl: "4" },
            },
            [
              _c(
                "b-row",
                { staticClass: "d-lg-none" },
                [
                  _c("b-col", [
                    _c(
                      "h3",
                      { staticClass: "bold d-letter-spacing onboarding-title" },
                      [_vm._v(" Onboarding Completion ")]
                    ),
                  ]),
                  _c("b-col", [
                    _c("h3", {
                      staticClass:
                        "bold d-letter-spacing onboarding-title text-muted text-right",
                    }),
                  ]),
                ],
                1
              ),
              _c("b-progress", {
                staticClass: "step-progress d-lg-none mb-5",
                attrs: {
                  value: _vm.mobileProgressDisplay,
                  max: 100,
                  variant: "purple",
                  height: "5px",
                },
              }),
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            show: _vm.alert.show,
                            variant: _vm.alert.type,
                          },
                        },
                        [_vm._v(_vm._s(_vm.alert.message))]
                      ),
                      _vm.step == "information" &&
                      _vm.userData.onboardingversion == "V3"
                        ? _c("VerificationInfo", {
                            attrs: {
                              "analytics-id": _vm.analyticsId,
                              state: _vm.state,
                              phoneno: _vm.userData.phoneno,
                              email: _vm.userData.email,
                              firstname: _vm.userData.firstname,
                              lastname: _vm.userData.lastname,
                              onboardingflow: _vm.onboardingflow,
                            },
                            on: { setStep: _vm.setStep },
                          })
                        : _vm._e(),
                      _vm.step == "information" &&
                      _vm.userData.onboardingversion != "V3"
                        ? _c("PatientInformation", {
                            attrs: {
                              nonrx: _vm.userData.nonrxmedicationonly,
                              onboardingData: _vm.userData,
                              "analytics-id": _vm.analyticsId,
                              onboardingflow: _vm.onboardingflow,
                            },
                            on: {
                              setStep: _vm.setStep,
                              showAlert: _vm.showAlert,
                              updateUserInfo: _vm.updateUserInfo,
                            },
                          })
                        : _vm._e(),
                      _vm.step == "idphoto"
                        ? _c("IDPhoto", {
                            attrs: {
                              onboardingflow: _vm.onboardingflow,
                              state: _vm.state,
                              "analytics-id": _vm.analyticsId,
                            },
                            on: {
                              setStep: _vm.setStep,
                              showAlert: _vm.showAlert,
                              hideAlert: _vm.hideAlert,
                            },
                          })
                        : _vm._e(),
                      _vm.step == "personalphoto"
                        ? _c("PersonalPhoto", {
                            attrs: {
                              onboardingflow: _vm.onboardingflow,
                              state: _vm.state,
                              "analytics-id": _vm.analyticsId,
                            },
                            on: {
                              showAlert: _vm.showAlert,
                              hideAlert: _vm.hideAlert,
                              setStep: _vm.setStep,
                            },
                          })
                        : _vm._e(),
                      _vm.step == "complete"
                        ? _c("Complete", {
                            attrs: { body: _vm.userData },
                            on: {
                              exit: function ($event) {
                                _vm.exiting = true
                              },
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "d-block text-center mt-4 mb-4" },
                        [
                          _c("div", { staticClass: "d-none d-lg-block mt-4" }),
                          _c(
                            "small",
                            [
                              _c("b-icon", { attrs: { icon: "lock-fill" } }),
                              _vm._v(" 256-BIT TLS SECURITY"),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "3", xl: "4", xxl: "4" } },
            [
              _c(
                "b-row",
                {
                  staticStyle: { height: "100%" },
                  attrs: { "align-v": "stretch" },
                },
                [
                  _c("b-col", { attrs: { "align-self": "end", cols: "12" } }, [
                    _vm.step == "idphoto"
                      ? _c("div", { staticClass: "notice" }, [
                          _c("p", { staticClass: "label-header" }, [
                            _vm._v("Ensure that:"),
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("- Your ID is not cutoff"),
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("- The photo is not blurry or dark"),
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "- Your ID is government issued and not expired"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "d-none d-lg-block mb-5" }),
                    _c("div", { staticClass: "d-block d-lg-none mt-4" }),
                    _c("div", {
                      staticClass: "certified-logo text-center",
                      attrs: { id: "certified-logo" },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }